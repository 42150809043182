<template>
    <div class="service-system-wrap">
        <img class="example-headImg" src="../assets/image/banner-service-system.png"/>
        <div class="head-explain title-wrap">
            <div class="head-explain-description">让客户成功，才是我们的成功</div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom: 10rem;">
          <div class="content">
            <div class="message">画简信息科技致力于为企业和个人提供简单、高效、实用的IT服务及项目研发管理解决方案，帮助客户在数字化时代中快速响应市场变化，实现业务价值的最大化。</div>
            <div class="title">服务体系</div>
            <div class="list">
              <div v-for="(item, index) in list" :key="index" class="item">
                <div class="card">
                  <div class="card-header">
                    <div class="card-label">{{ item.label }}</div>
                    <img :src="item.icon" alt="" class="card-icon">
                  </div>
                  <div class="card-content">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesServiceSystem',
    metaInfo:{
        meta:[
        { name:'keywords', content:'IT 服务门户' },
        { name:'description', content:'画简信息科技致力于为企业和个人提供简单、高效、实用的IT服务及项目研发管理解决方案，帮助客户在数字化时代中快速响应市场变化，实现业务价值的最大化。' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
            list: [
              {
                label: '简化复杂流程',
                content: '在IT服务和项目研发过程中，繁琐的流程往往阻碍效率的提升。因此，我们专注于通过创新的解决方案，将复杂的流程简单化自动化，让您能够轻松应对各种挑战。',
                icon: require('../assets/image/icon-service-system-1.svg'),
              },
              {
                label: '24小时AI助手服务',
                content: '基于企业知识库及历史工单内容，精准地根据用户提出的问题，即时生成相应的回答与解决方案。有效提升员工自助解决问题的能力，减轻运维团队工作负担。',
                icon: require('../assets/image/icon-service-system-2.svg'),
              },
              {
                label: '高效执行与交付',
                content: '我们注重执行力和交付速度。通过精心设计的项目管理流程和高效的工作团队，我们能够确保项目按时、按质、按量完成，为客户创造更大的商业价值。',
                icon: require('../assets/image/icon-service-system-3.svg'),
              },
              {
                label: '贴合客户实际需求',
                content: '我们始终坚持以客户为中心，深入了解客户的实际需求，提供定制化的IT服务和项目研发管理解决方案，能够紧密贴合您的业务需求，帮助您实现真正的价值提升。',
                icon: require('../assets/image/icon-service-system-4.svg'),
              },
              {
                label: '全方位的服务与支持',
                content: '我们提供全方位的服务与支持，包括项目咨询、方案设计、实施部署、培训支持以及售后服务等。确保您在使用我们的平台时能够获得最佳的用户体验和价值回报。',
                icon: require('../assets/image/icon-service-system-5.svg'),
              },
              {
                label: '持续创新与发展',
                content: '我们拥有强大的技术团队，不断推动技术和产品的创新与发展。可以为您提供最新、最先进的IT服务和项目研发管理解决方案，帮助您在竞争中保持领先地位。',
                icon: require('../assets/image/icon-service-system-6.svg'),
              },
            ]
        };
    },
    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
    },
    destroyed () {
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.head-explain-description {
  font-weight: 500;
  font-size: 5rem;
  color: #FFFFFF;
  line-height: 7.5rem;
}

.service-system-wrap {
  .title-wrap {
    position: absolute;
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);

  }
  .content {
    width: 139rem;
    margin: 4rem 0 0 26.1rem;
  }

  .message {
    font-weight: 400;
    font-size: 2.4rem;
    color: #666666;
    line-height: 4rem;
  }

  .title {
    padding: 8rem 0 6rem;
    font-weight: 500;
    font-size: 4rem;
    color: #111111;
    line-height: 6rem;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4rem;
  }

  .item {
    flex: none;
    flex-basis: 33.33%;
    padding-left: 4rem;
    padding-bottom: 6rem;
  }

  .card {
    border-radius: .8rem;
    box-shadow: 0 0 1.5rem 0 rgba(0,15,6,0.08);
    overflow: hidden

  }

  .card-header {
    display: flex;
    align-items: center;
    padding: 4rem 4rem 4rem 2rem;
    background-color: #EFEFEF;
  }

  .card-label {
    flex: 1;
    font-weight: 500;
    font-size: 2.4rem;
    color: #111111;
    line-height: 3.6rem;
    text-align: left;
  }

  .card-icon {
    flex: none;
    margin-left: 1.6rem;
    width: 4rem;
    height: 4rem;
  }

  .card-content {
    padding: 2rem;
    background-color: #FFFFFF;
    font-weight: 400;
    font-size: 2rem;
    color: #666666;
    line-height: 3.6rem;
    text-align: justify;
  }
}
</style>
